import { load } from 'recaptcha-v3'
import axios from 'axios'
import * as EmailValidator from 'email-validator';

const reKey = "6Ld4sagaAAAAADs_3eK3GDRTKsOdSvVuuMxaW1QF"

$(document).ready(()=>{
    $('.popup-with-form').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#name',
        mainClass: 'my-mfp-zoom-in',
        callbacks: {
            open: function() {
                $('html').addClass('lightbox-opened');
            },
            close: function() {
                $('html').removeClass('lightbox-opened');
            }
        }
    });

	let vForms = document.querySelectorAll("form");
	vForms.forEach((elForm)=>{
		let btn = elForm.querySelector('[type="submit"]');
		btn.onclick = async (evt) => {						
			evt.preventDefault();

			try {
				let sEmail = ""
				let sName = ""
				let sPhone = ""

				let elName = elForm.querySelector("input.UserName")
				let elEmail = elForm.querySelector("input.UserEmail")
				let elPhone = elForm.querySelector("input.UserPhone")

				if (elName!=null)
					sName = elName.value;

				if (elEmail!=null)
					sEmail = elEmail.value;

				if (elPhone!=null) 
					sPhone = elPhone.value;

				if (sName=="") 					
					return ShowError(elName, "E' necessario inserire il nominativo");
		
				if (elEmail!=null) {
					if (sEmail=="") 					
						return ShowError(elEmail, "E' necessario inserire la email");									
					if (EmailValidator.validate(sEmail) != true)
						return ShowError(elEmail, "La email inserita non è valida");
				}

				if (elPhone!=null && sPhone=="") 
					return ShowError(elPhone, "E' necessario inserire il numero di telefono");
								
				if (sEmail == "")
					sEmail = "no_mail_"+(new Date().toISOString().replaceAll("-","").replaceAll(":","").replaceAll(".",""))+"@nextdigitalservice.it"

				await SubmitModule(evt.target, sEmail,sName,sPhone);
				elName.value = "";
				if (elEmail!=null) elEmail.value = "";
				if (elPhone!=null) elPhone.value = "";

				$.magnificPopup.close()
				$("#ThankYouForm").modal();

			}
			catch(Ex) {
				ShowError(evt.target, "Ooops! Il server non ha risposto correttamente");
			}
			return false;
		}
		
	})
});

function ShowError(elFormField, sErr) {

	let elErr = elFormField.closest("form").querySelector(".error")
	if (elErr != null) {
		elErr.classList.remove("hidden")
		elErr.innerText = sErr		
	}

	let fnOnBlur = (evt) => {
		evt.target.classList.remove("is-invalid")
		let elForm = evt.target.closest("form")
		if (elForm!=null) {
			let elErr = elForm.querySelector(".error")
			if (elErr != null)
				elErr.classList.add("hidden")
		}
		
		evt.target.removeEventListener("input",fnOnBlur)
		if (elFormField.type=="submit")
			elFormField.removeEventListener("click",fnOnBlur)
	}
	elFormField.classList.add("is-invalid")
	elFormField.addEventListener("input",fnOnBlur)
	if (elFormField.type=="submit")
		elFormField.addEventListener("click",fnOnBlur)

	elFormField.focus()
}

async function SubmitModule(elSubmitButton, sEmail,sName,sPhone) {
	
	let Text = elSubmitButton.value
	elSubmitButton.setAttribute("disabled",true)
	elSubmitButton.value = "Invio in corso"
	let bErr = false;
	try {
		let recaptcha = await load(reKey)
		let token = await recaptcha.execute('submit');
		//let response = await axios.post(`http://localhost:5001/nextds-website/us-central1/hubspot_submit`,
		let response = await axios.post(`/api/hubspot_submit`,
					{
						"token": token,
						"email": sEmail,
						"name": sName,
						"phone": sPhone
					}) 
	}
	catch(Ex) {
		console.log(Ex);		
		bErr  = true;
	}

	elSubmitButton.removeAttribute("disabled")
	elSubmitButton.value = Text;

	if (bErr == true)
		throw "Error"
}

window.asyncFunction = SubmitModule;